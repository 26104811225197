import PropTypes from "prop-types"
import React from "react"
import handleViewport from "react-in-viewport"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import AnchorLink from "react-anchor-link-smooth-scroll"

const inViewportHandlerOptions = {
  threshold: 0.8,
}

const AnimationColumn = handleViewport(
  ({ inViewport, children: video, forwardedRef }) => {
    const videoPlayer = React.useRef(null)

    React.useEffect(() => {
      if (inViewport) {
        videoPlayer.current.currentTime = 0
        videoPlayer.current.play()
      } else {
        videoPlayer.current.pause()
      }
    })

    return (
      <div className="animation-column px-3" ref={forwardedRef}>
        <video
          ref={videoPlayer}
          muted="muted"
          playsinline="playsinline"
          autoPlay="autoPlay"
          loop
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
    )
  },
  inViewportHandlerOptions
)

const AnimationSection = ({
  title,
  description,
  video,
  direction,
  ctaText,
}) => {
  const DescriptionColumn = (
    <div
      className="text-column px-3"
      style={{
        alignSelf: "center",
      }}
    >
      <h3
        className="title has-text-weight-light"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h3>
      <p>{description}</p>
      <AnchorLink
        href="#request-a-demo"
        offset="160"
        className="arrow-link mt-5"
      >
        <FontAwesomeIcon
          icon={faArrowRight}
          className="arrow-link--icon"
          fixedWidth
          size="lg"
        />
        {ctaText}
      </AnchorLink>
    </div>
  )

  return (
    <section className="animation-section section container" direction={direction}>
      <AnimationColumn>{video}</AnimationColumn>
      {DescriptionColumn}
    </section>
  )
}

AnimationSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  description: PropTypes.string,
  video: PropTypes.string,
  direction: PropTypes.string,
}

export default AnimationSection
