import React from "react"

export default [
  {
    title: "<strong>Predict</strong> ideal audiences to target for each content",
    description: "Stop guessing who to target across each advertising channel. Let our AI scan your content to understand what it is about and recommend audiences to target across each channel proven to perform for similar content.",
    video: require("../assets/videos/cd/1-new.mp4"),
    direction: 'ltr',
  },
  {
    title: "Find the <strong>right audiences</strong> for the right cost",
    description: "Select your desired outcome and let our AI predict, test and optimize each piece of content against every possible variable to deliver the lowest cost-per-outcome. AI automatically pushes every variation of headlines, images, audience interests, demographics, and devices as separate ad variations to your desired ad channels through API and optimizes bids and budgets in real-time to deliver the lowest cost-per-outcome.",
    video: require("../assets/videos/cd/2.mp4"),
    direction: 'rtl',
  },
  {
    title: "Drive desired <strong>outcomes</strong> with smart call-to-actions",
    description: "Drive users to take your desired actions by testing various call-to-action image and copy and automatically showing the right call-to-action at the right time for each piece of content to drive engagement, positive consideration, leads, and conversions.",
    video: require("../assets/videos/ci/1.mp4"),
    direction: 'ltr',
  },
  {
    title: "Capture <strong>high performing</strong> first party audiences",
    description: "Build high quality custom audiences based on real user engagement such as amount of time spent with your content, percentage of content scrolled, and interactions with your call-to-actions. Feed your retargeting pool with quality scalable first party audiences.",
    video: require("../assets/videos/ci/4.mp4"),
    direction: 'rtl',
  },
  {
    title: "Only pay for <strong>engaged traffic</strong> with CPE cost caps ",
    description: "With 2 out of every 3 users bouncing before they spend a minimum of 15-seconds with your content our CPE cost-caps ensure you deliver a minimum number of engaged users that spend at least 15-seconds with your content. Our cost control algorithms will predict ad variations most likely to come below your desired cost caps and eliminate non-efficient ad variations to manage delivery of your campaign without going over your cost caps.",
    video: require("../assets/videos/cd/3.mp4"),
    direction: 'ltr',
  }
]
