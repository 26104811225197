import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Layout from "../components/layout"
import AgenciesMarquee from "../components/agencies-marquee"
import ProductHero from "../components/product-hero"
import AnimationSection from "../components/animation-section"
import RequestDemoSection from "../components/request-demo-section"
import ProductPreview from "../components/product-preview"
import ContentDistributionContent from "../content/content-distribution"
import SEO from "../components/seo"
import productVideo from "../assets/videos/cd/top-1.mp4"

const CTA_TEXT = "Request Demo"

class ContentDistributionPage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (
      typeof window !== "undefined" &&
      document.location.href.indexOf("#demo") !== -1
    ) {
      setTimeout(() => {
        document.querySelector("#request-a-demo").scrollIntoView({
          behavior: "smooth",
        })
      }, 300)
    }
  }

  render() {
    return (
      <Layout>
        <SEO
          title="AI-Powered Content Distribution Platform"
          description="Maximize ROI of your paid content distribution spend by automatically finding cost-effective audiences across native advertising platforms."
        />
        <ProductHero
          title={[
            "Find ",
            <strong>Cost-Effective</strong>,
            " Audiences for Your ",
            <strong>Content</strong>,
          ]}
          subtitle="Let our AI predict, test, and optimize every variable to find cost-effective audiences"
        >
          <AnchorLink href="#request-a-demo" offset="160">
            <button className="button is-cta-button">{CTA_TEXT}</button>
          </AnchorLink>
        </ProductHero>
        <ProductPreview video={productVideo} />
        {ContentDistributionContent.map((section, index) => (
          <AnimationSection
            key={section.index}
            title={section.title}
            description={section.description}
            video={section.video}
            direction={section.direction}
            ctaText={CTA_TEXT}
          />
        ))}
        <RequestDemoSection
          title={CTA_TEXT}
          formPlacement="Content Distribution"
        />
        <AgenciesMarquee />
      </Layout>
    )
  }
}

export default ContentDistributionPage
