import PropTypes from "prop-types"
import React from "react"
import handleViewport from "react-in-viewport"

const ProductPreview = ({ video, inViewport, forwardedRef }) => {
  const videoPlayer = React.useRef(null)

  React.useEffect(() => {
    if (inViewport) {
      videoPlayer.current.currentTime = 0
      videoPlayer.current.play()
    } else {
      videoPlayer.current.pause()
    }
  })

  return (
    <section ref={forwardedRef} className="is-hidden-touch page-hero-image">
      <video
        ref={videoPlayer}
        muted="muted"
        playsinline="playsinline"
        autoPlay
        loop
        height={676}
        width={961}
      >
        <source src={video} type="video/mp4" />
      </video>
    </section>
  )
}

ProductPreview.propTypes = {
  video: PropTypes.string,
}

export default handleViewport(ProductPreview, {
  threshold: 0.8,
})
